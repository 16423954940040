
/*
 * .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .
 *    .       .       .       .       .       .       .       .       .       .       .       .
 *      . :SXt. .  :%X%. . ..%S%: . .    . .    .  .    .  .    .  .    .  .    .  .    .  .    .  . .
 *  .   .;8XSXt8 .S8@SX8@  888SS8t.    ..8  %t    .  .      .       .       .   ;X  X:      .
 *    . @%@:8;S8 t8%t8 X8.:88%8.X8X     ;8;:S; .       .  .   . ;;.   .  .    . t8.:X:.t..    .  . .  .
 *   .  8XXS tS8;X88S.SX8::8X; 8X8@ .  .:X;     . .  .   .      t@     .   .   .88    t;8   .   .
 *      8S88:@8@8888S X8888888.8X8@   . @ . . .  ...   .   .. . ;8  .    .   . .8S .  %8: .   .    . .
 *   . .8X88. ;8S%8;.  .8;:8%..8@8@   :S88.S@ ;t@::S S.  @;XttSS88;;;8;;   . %t:@8%:t X8; 8@% :; .
 *      8@@S  .  ...   .. .:. .8X8@ .   S;.:  X8    ;8X t8S     t8   .@;  .88; .8%. . @8.  :8 .8   . .
 *   . .8@@8;  .     .  .     .8X8@    .tX;  ;88.    X@ t8t     t8 .  88S t8@  :8S    @@%   t88.
 *      @@88:     .      .  . .8@8@  . .X .  :8: .  .@S t8@     S@.  . 88%8:.  .8S .  88:  X8%8S.  .  .
 *   .  8X88. .  .   . .   .  .8X8@     X : ..88t%;S@8X %8%  . .:8.     %88t  .:8S   .X8% S8S:X88:
 *     .8XXS    .   .         .8X8@ . ..88.    tXX SS%  :8t     @8. .  .888.   .@S .  tX: 88.. SS:  .
 *   .  8@88:.    .     . .  . 8X8@    .:. .    .: .  . ..  . .  .     S8%. .  .     .    ..       .  .
 *     .@@88:  .     .         8X8@    .     . .   .      .     .  .   SX    .   .      .      .
 *   .  8XX8;    .    .  .  . .8X8X .   . .      .   .  .    .       .88X. .      . . .    .  .  .  .
 *      ;. %  .    .       .  .;:;:.  .     .  .           .   . .  . :;      . .        .         .  .
 *   . . .  .   .   .  .             .   .    .   . . .  .               .  .      .  .    . .  .
 *        .       .      .  . .  .     .   .    .       .   . .  . .  .    .   .        .        .  .
 *   .  .    . .     .    .     .  .     .   .     .  .   .            .  .      . . .    .  . .     .
 *
 * Copyright FortyFix(c) 2024.
 * Title: Verifica.ts;
 * Path: /Volumes/Dati/SbSW_SoftwareBySystem_Works/Fortyfix/GESFF_Gestionale_FF_Angular/src/environments/BaseEnvironments/Verifica.ts;
 * Description: ;
 * Author: giovannibattistadellaporta;
 * Created at: 31/05/24, 10:51;
 * Updated at: 31/05/24, 10:51;
 *
 *
 */

import { Permission } from "src/app/Login/_guards/Permission";
import { LISTComponent } from "src/app/task/list/list.component";

export var Environment = ()=> {
  var env:any = {
    apiUrl: 'https://gestionale.verificaspa.it/api',
    fileUpdate: true,
    langSuffix: '_Verifica.json',
    baseUrl: 'task/list/RA/TT',
    href: '/Login',
    homepages: [
      {Ruoli: null, RuoliAccesso: [22,29], link: "/progetti/progetti/CA"},
      {Ruoli: null, RuoliAccesso: [28,12], link: "/task/list/RA/IC"},
      {Ruoli: null, RuoliAccesso: [31,32,26], link: "/progetti/progetti"}
    ],
    logoPath: 'assets/images/Verifica.png',
    logoBg: 'skin6', // six possible values: skin(1/2/3/4/5/6),
    navbarbg: 'skin2', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
    theme: 'light',
    TelegramBotToken:"6990226380:AAHWBH4C1dDsnGvDFU0XB6H5dnRJiYK36Hw",
    TelegramChatId:-4248506776,
    TelegramBotPlatformName:"Verifica",
    ClientiType: ['C', 'F'],
    IDTecnico: "RT",
    Modules: {
      "twoFactorAuth": true,
      "Admin": true,
      "Personale": true,
      "Clienti": true,
      "Interventi": false,
      "Prima_Nota": false,
      "Listini": false,
      "Progetti": true,
      "Prodotti": true,
      "Documenti": true,
      "Scadenzario": true,
      "Spedizioni": false,
      "Stampe": true,
      "Task": true,
      "Shared": true,
      "Dashboard": true,
      "Settings": true,
      "Formazione": true,
      "VersioneCarbotti": false,
      "PartiProdotto": false,
      "Parti": false,
      "Rma": true,
      "Ecommerce": false,
      "ScadenzeFiglie": false,
      "Registrazione": false,
      "Ticket": true,
      "Acquisti": false,
      "taskboard": false,
      "Camera": true,
      "Valutazioni": true,
      "Verifica": true,
      "ForceReset": true,
    },
    File: {
      hideData: true
    },
    Alerts: [
      {
        restRoute: '/rma/rma/alerts',
        icon: 'far fa-bell',
        map: (x) => { return x.map(y => { return { value: y.commessa } }) },
        id: 'alertDateCommesse',
        canRead: (x, permission: Permission) => {return permission?.isOperativeRoles(['AZ','SA'])},
        onClick: (x) => { },
        trigger: 'taskList'
      }
    ],
    TaskAddon: {
      isTuttiVisible: (ListComponent: LISTComponent) => { 
        const _permittedRoles: string[] = ['RT', 'Amministrazione', 'SP', 'Commerciale', 'All', 'SC']
        var visible: boolean = false;
        _permittedRoles.forEach(role=>{
          var Ra = ListComponent.AuthService?.currentUserValue?.ruoliAccessoObject?.find(x => x.ruolo?.toUpperCase() == role.toUpperCase() || x.id == role);
          var R = ListComponent.AuthService?.currentUserValue?.ruoliObject?.find(x => (typeof x.id == 'string' && x.id?.toUpperCase() == role.toUpperCase()) || x.id == role || x.ruolo?.toUpperCase() == role.toUpperCase());
          visible = visible || Ra != undefined || R != undefined;
        })
        return visible;
      },
      checkList: true,
      periodo: false,
      sidebarFilter: [
        {
          nome: 'Cliente',
          source: 'Clienti',
          OnFilter: 'filtroClienti',
          oggetti: ['cliente'],
          internalOutput: 'clienteObject',
          OutputField: 'cliente',
          OnChange: 'OnClienteChange',
          modificabile: true,
          inputType: 'singleDropdown',
          placeholder: 'Seleziona un Cliente',
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'nominativo',
            searchPlaceholderText: "Cerca",
            allowSearchFilter: true,
            allowRemoteDataSearch: true,
          },
          class: 'col-lg-12',
          visibleifProhibited: true
        },
        {
          nome: 'Responsabile',
          source: '/anagrafica/persone/list',
          parameter: [{nome:"page", value: 1}, {nome:"size", value: 200}],
          responseHasData: true,
          oggetti: ['manager'],
          internalOutput: 'managerObject',
          OutputField: 'persona',
          OnChange: 'OnManagerChange',
          OnFilter: 'filtroManager',
          modificabile: true,
          inputType: 'singleDropdown',
          allowedCapacity: ['gestione-rma'],
          placeholder: 'Seleziona un Responsabile',
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'fullName',
            searchPlaceholderText: "Cerca",
            allowSearchFilter: true,
            allowRemoteDataSearch: true,
          },
          class: 'col-lg-12',
          visibleifProhibited: false
        },
        {
          modificabile: true,
          nome: 'codice',
          oggetti: ['codice'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-12',
          visibleifProhibited: true
        },
      ],
      ListTableHeaderField:[
        {nome: "Titolo", oggetti: ["type", "nome"],type: 'replace', replacement: [{original: 'C', replace:'CRM'}, {original: 'S', replace:'Scadenza'}, {original: 'R', replace:''},{original: 'W', replace:'Visita'},{original: 'Z', replace:'Attività Laboratorio'}], color:[{colore: 'colore', fieldexist:'id'}] },
        {nome: "Stato", oggetti: ['nomeStato'],  filtertype: ['TT']},
        {nome: "Ragione Sociale", oggetti: ['clienteObject'], type: "object", field:['nominativo']},
        {nome: "Codice Cliente", oggetti: ['clienteObject'], type: "object", field:['codiceGamma'], filtertype: ['F','DF']},
        {nome: "Luogo di Intervento", oggettisec: ['acquirente','acquirente','acquirente','acquirente'], oggetti: ['ritiro','ritiro','ritiro','ritiro'], type: "object", field:['nominativo','indirizzo','citta', 'provincia'], filternottype: ['DF','F']},
        {nome: "Data Impegno", oggetti: ['contrattoObject'], type: "object", inputType: 'date', field:['data_accettazione'], filternottype: ['DF','F', 'DC'] },
        {nome: "Data Chiusura Tecnico", inputType: 'date', oggetti:['data_chiusura_tec'], filtertype: ['DC'] },
        {oggetti:['data_inizio_prevista'], nome: "Prima Verifica Entro", inputType: 'date',  filternottype: ['DF','F'] },
        {nome: "Tot. Commessa", oggetti: ['contrattoObject'], type: "object", inputType: 'number', field:['totCommessa'], filtertype: ['DF','F'] },
        {nome: "Tot. Fatturato", oggetti: ['contrattoObject'], type: "object", inputType: 'number', field:['totFatturato'] , filtertype: ['DF','F']},
        {nome: "Tot. Pagato", oggetti: ['contrattoObject'], type: "object", inputType: 'number', field:['totPagato'], filtertype: ['DF','F']},
        {nome: "Data Fattura", oggetti: ['dataFattura'], inputType: 'date', filtertype: ['F',"TT"]},
        {nome: "Scadenza Pagamento", oggetti: ['dataScadenza'], inputType: 'date', filtertype: ['F']},
        {nome: "Fattura N.", oggetti: ['numeroFattura'], filtertype: ['F']},
        {nome: "Ispettore", oggetti:['managerObject'], type: 'object', field: ['fullName'], filternottype: ['DA','DP','DAP']},
        /*{nome: "Tipologia", oggetti: ['type'], type: 'replace', replacement: [{original: 'C', replace:'CRM'}, {original: 'S', replace:'Scadenza'}, {original: 'R', replace:'Attività'},{original: 'W', replace:'Visita'}]},*/
      ],
      DynamicFields:[
        {
          type: 'Z',
          addButton: false,
          restRoute: '/rma/rma',
          hideif:{},
          Label: 'Laboratorio',
          Azioni:[
            {nome: 'Aggiungi Contatore' ,close:false, class:' btn ',  labelClass:'ml-1 text-info font-weight-bold',type: 'POST', restRoute: '/rma/contatore/add', icon:'fas fa-plus-circle text-info', placeholder: 'Aggiungi Contatore', parameter: [{nome:"padre", parameter: 'id'}, {nome:"nome", value: 'Nuovo Contatore'},{nome:"alert_inizio_task", value: '0'},{nome:"alert_fine_task", value: '0'}]},
            /* {nome: 'Elimina',close:true, class:' btn ',  labelClass:'ml-1 text-danger font-weight-bold', type: 'POST', restRoute: '/task/rma/delete', preAlert: 'Sei sicuro di voler eliminare questo elemento?', capacita: ['gestione-news'], icon:'fas fa-trash text-danger', placeholder: 'Elimina', successMex: 'Elemento eliminato con successo', parameter: [{nome:"id", parameter: 'id'}]},
            */],
          sezioni: [
            {
              sezione: 'Scheda Cliente',
              campo: 'clienteObject',
              campi: [
                {nome:'Cliente:', oggetti: ['nominativo'], field: ['nominativo'], class: 'col-lg-11', inputClass:'ml-3'},
                {nome:'Tel:', oggetti: ['telefono'], field: ['telefono'], class: 'col-lg-2', inputClass:'ml-3'},
                {nome:'Email:', oggetti: ['email'], field: ['email'], class: 'col-lg-4', inputClass:'ml-3'},
                {nome:'Pec:', oggetti: ['pec'], field: ['pec'], class: 'col-lg-4', inputClass:'ml-3'},
                {nome:'Indirizzo:', oggetti: ['indirizzo'], field: ['indirizzo'], class: 'col-lg-11', inputClass:'ml-3'},
                {nome:'Referente:', oggetti: ['referenti'],  type:"object", field: ['fullName'], class: 'col-lg-5', inputClass:'ml-3'},
                {nome: '', oggetti: ['referenti'], type:"object", field: ['telefono'], class: 'col-lg-6', inputClass:'ml-3'},
                {nome:'Comune:', oggetti: ['citta'], class: 'col-lg-9', inputClass:'ml-3'},
                {nome:'Provincia:', oggetti: ['provincia'], class: 'col-lg-3', inputClass:'ml-3'},
              ]
            },
            {
              sezione: 'Commessa',
              campi:[
                {nome:'Referente Impresa:', oggetti: ['commessaObject'], field:['referente','nominativo'], type:'object', class: 'col-lg-4', inputClass:'ml-3'},
                {nome:'Tel Referente:', oggetti: ['commessaObject'], field:['referente','telefono'], type:'object', class: 'col-lg-4', inputClass:'ml-3'},
                {nome:'Email Referente:', oggetti: ['commessaObject'], field:['referente','email'], type:'object', class: 'col-lg-4', inputClass:'ml-3'},
                {nome:'Mansione Referente:', oggetti: ['commessaObject'], field:['referente','mansione'], type:'object', class: 'col-lg-4', inputClass:'ml-3'},
                {nome:'Attività Impresa:', oggetti: ['commessaObject'], field:['oggetto'], class: 'col-lg-4', inputClass:'ml-3'},
                {nome:'Protocollo:', oggetti: ['protocollo'], class: 'col-12', inputClass:'ml-3'},
                {nome:'Data apertura:', oggetti: ['contrattoObject'], field: ['data_creazione'], inputType: 'date', class: 'col-lg-4', inputClass:'ml-3'},
                {nome:'Importo Contabilizzato:', oggetti: ['contabilizzato'], class: 'col-lg-4', inputClass:'ml-3', inputType: 'euro'},
                {nome:'Acconti Pagati:', oggetti: ['accontoPagato'], class: 'col-lg-4', inputClass:'ml-3', inputType: 'euro'},
                {nome:'Ubicazione Impianto:', oggetti: ['acquirente','acquirente','acquirente','acquirente'], field: ['indirizzo','cap','citta','provincia'], class: 'col-lg-12', inputClass:'ml-3'},
                {nome:"Latitudine:", oggetti: ['acquirente'],field: ['lat'], type:'object', inputType:'number', modificabile: true, class: 'col-lg-3', warning:'Attenzione il campo Latitudine non è stato compilato!'},
                {nome:"Longitudine:", oggetti: ['acquirente'],field: ['lon'], type:'object', inputType:'number', modificabile: true, class: 'col-lg-3', warning:'Attenzione il campo Longitudine non è stato compilato!'},
              ]
            },
            {
              sezione:'Programmazione Attività',
              campi:[
                {nome:'Prima Verifica Entro:', oggetti: ['data_inizio_prevista'], modificabile:true , inputType:'date', class: 'col-lg-3', warning:true },
                {nome:'Data Appuntamento:', oggetti: ['data_fine_prevista'], modificabile:true , inputType:'date', class: 'col-lg-3', warning:true  },
                {nome:'Straordinaria:', oggetti: ['straordinario'], type:'boolean', inputType:'checkbox', modificabile: true, class: 'col-lg-2' },
                {nome:'Commessa DPR/462:', oggetti: ['accreditato'], type:'boolean', inputType:'checkbox', modificabile: true, class: 'col-lg-2' },
                {nome:'Rinnovo Automatico:', oggetti: ['rinnovo'], type:'boolean', inputType:'checkbox', modificabile: true, class: 'col-lg-2' },
                {nome:'Note:', oggetti: ['note'], inputType:'textarea', modificabile: true, class: 'col-lg-12'},
                {nome:"Riferimento dell'offerta:", inputType:'textarea', type:'object', oggetti: ['commessaObject'], field:['codiceContratto'], modificabile:false, class: 'col-lg-4' , warning:true },
                {nome:'Preventivo €:', oggetti: ['prezzo'], modificabile: true, inputType: 'number', class: 'col-lg-4', warning:true },
                {
                  modificabile: true,
                  nome: 'Ispettore',
                  oggetti: ['manager'],
                  inputType: 'singleDropdown',
                  source: 'PersoneSource',
                  internalOutput: 'managerObject',
                  OnFilter: 'filtroManager',
                  OnChange: 'OnManagerChange',
                  OutputField: 'manager',
                  placeholder: 'Seleziona',
                  settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'fullName',
                    searchPlaceholderText: "inserire almeno 3 caratteri",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: true,
                  },
                  class: 'col-lg-3',
                  visibleifProhibited: true, warning:true
                },
                {nome:"Note dell'Ispettore:", oggetti: ['noteIspettore'], inputType:'textarea', modificabile: true, class: 'col-lg-3'},
                {nome:'Attivitá di Laboratorio', oggetti: ['laboratorio'], type:'checkboxArray', class: 'col-lg-12', modificabile: true  },
                {nome:'Tariffa INAIL:', oggetti: ['inail'], modificabile:true, inputType: 'number', class: 'col-lg-12 d-flex align-items-center', inputClass:'ml-3', inputStyle: {width: '200px'}, warning:true },
              ]
            },
            {
              sezione:'Riesame rapporti di ispezione',
              campi:[
                {nome:'Chiusura Commessa:', oggetti: ['data_inizio_effettiva'],  inputType: 'date', modificabile:true, class: 'col-lg-4', warning:true },
                {nome:'Emissione Certificato:', oggetti: ['data_fine_effettiva'],  inputType: 'date', modificabile:true, class: 'col-lg-4', warning:true },
                {nome:'Firmato', oggetti: ['chiuso'], type:'boolean', inputType:'checkbox', modificabile: true, class: 'col-lg-3', warning:true  },
              ]
            },
            {
              sezione:'',
              campi:[
                {nome: "Files", type: "Files", url:"/rma/rma/file", class: 'col-lg-12', OnChange: 'OnFilesChange', GestioneFile: true, modificabile: true},
                {
                  nome: 'Contatori',
                  oggetti: ['cotatori'],
                  type:"table",
                  tasktype:"WL",
                  hideif:{fieldnotexist:'id'},
                  header:[
                    {nome: "Contatore", oggetti: ['nome'], style:{'margin-left':'10px'},},
                    {nome: "Tecnico", oggetti: ['tecnico'],style:{'margin-left':'10px'},},
                  ],
                  source:"/rma/contatore/list",
                  parameter: [{nome:"page", value: 0}, {nome:"size", value: 200}, {nome: "padre", parameter: "id" }],
                  responseHasData: true,
                  modificabile: true,
                  class: 'col-lg-12',
                  azioni:[
                    {ExternalOnClick: 'OnTableEdit', icon: ' fas fa-pencil-alt', tooltip:'Edit', color: 'blue'},
                  ]
                },
              ]
            }
          ],
        },
        {
          type: 'WL',
          addButton: false,
          restRoute: '/rma/contatore',
          hideif:{},
          Label: 'Contatore',
          Azioni:[
            {nome: 'Elimina',close:true, class:' btn ',  labelClass:'ml-1 text-danger font-weight-bold', type: 'POST', restRoute: '/rma/contatore/delete', preAlert: 'Sei sicuro di voler eliminare questo elemento?', capacita: ['gestione-news'], icon:'fas fa-trash text-danger', placeholder: 'Elimina', successMex: 'Elemento eliminato con successo', parameter: [{nome:"id", parameter: 'id'}]},
          ],
          campi:[
            {nome:'Nome :', oggetti: ['nome'], modificabile:true , inputType:'text', class: 'col-lg-3', warning:true  },
            {nome:'Costruttore :', oggetti: ['costruttore'], modificabile:true , inputType:'text', class: 'col-lg-3', warning:true  },
            {nome:'Modello :', oggetti: ['modello'], modificabile:true , inputType:'text', class: 'col-lg-3' , warning:true },
            {nome:'Matricola :', oggetti: ['matricola'], modificabile:true , inputType:'text', class: 'col-lg-3', warning:true  },
            {nome:'Longitudine :', oggetti: ['lat'], modificabile:true , inputType:'text', class: 'col-lg-3' , warning:true },
            {nome:'Latitiudine :', oggetti: ['lon'], modificabile:true , inputType:'text', class: 'col-lg-3' , warning:true },
            {nome:'Note :', oggetti: ['note'], modificabile:true , inputType:'text', class: 'col-lg-3', warning:true  },
            {
              modificabile: true,
              nome: 'Ispettore',
              oggetti: ['manager'],
              inputType: 'singleDropdown',
              source: 'PersoneSource',
              internalOutput: 'managerObject',
              OnFilter: 'filtroManager',
              OnChange: 'OnManagerChange',
              OutputField: 'manager',
              placeholder: 'Seleziona',
              settings: {
                singleSelection: true,
                closeDropDownOnSelection: true,
                singleOutput: true,
                idField: 'id',
                textField: 'fullName',
                searchPlaceholderText: "inserire almeno 3 caratteri",
                allowSearchFilter: true,
                allowRemoteDataSearch: true,
              },
              class: 'col-lg-3',
              visibleifProhibited: true, warning:true
            },
            {nome: "Files", type: "Files", url:"/rma/contatore/file",attr:{types: [{label: 'Documenti Cliente', id: 'doc'},{label: 'Foto', id: 'pic'},{label: 'Certificati', id: 'cert'}]}, class: 'col-lg-12', OnChange: 'OnFilesChange', GestioneFile: true, modificabile: true}
          ]
        }
      ]

    },
    Personale:{
      RuoliOperativi:false,
      Tasks: false,
      Rma: true,
      disablePersona: true,
      Corsi:false,
      CampiAggiuntivi: [
        {nome:'Abilitazioni', oggetti: ['checks'], type:'checkboxArray', class: 'col-lg-12', modificabile: true  },
      ],
      Tabs: [
        { id: 'info', tipo: 'template', titolo: 'INFO', component: 'Edit' },
        { id: 'rma', tipo: 'task', titolo: 'Commesse', hideif: { fieldnotexist: 'id' }, params: { tipo: 'RA', filter : 'TT'} },
        { id: 'files', tipo: 'template', titolo: 'Files', component: 'Files', hideif: { fieldnotexist: 'id' } }
      ]
    },
    ClientiAddOn:{
      commerciale: true,
      segnalatore: true,
      segnalatoreInterno: true,
      sedi: true,
      attivita: true,
      ruolo: false,
      RuoloDefault:{id: "C", label: "Cliente"},
      referenti: true,
      interventi: true,
    },
    filtrife: [
      {id: "T", label: "Tutti", capacita: [], module: undefined},
      {id: "RA", label: "Commesse", capacita: [], tipo:"R", module: "Rma", SubFilter: [
          {id: "DAP", label: "Da Aprire", capacita: [], module: undefined},
          {id: "DP", label: "Da pianificare", capacita: [], module: undefined},
          {id: "DA", label: "Da approvare", capacita: [], module: undefined},
          {id: "DAc", label: "Da accettare", capacita: [], module: undefined},
          {id: "IC", label: "In corso", capacita: [], module: undefined, },
          {id: "DC", label: "Da chiudere", capacita: [], module: undefined},
          {id: "B", label: "Bloccate", capacita: [], module: undefined},
          {id: "C", label:  "Chiuse", capacita: [], module: undefined},
          {id: "DF", label:  "Da Fatturare", capacita: [], module: undefined},
          {id: "F", label:  "Da Incassare", capacita: [], module: undefined},
          {id: "TT", label:  "Tutte", capacita: [], module: undefined},
        ]},
      {id: "Z", label: "Commesse", capacita: [], tipo:"Z", module: "Rma", SubFilter: [
          {id: "DAP", label: "Da Aprire", capacita: [], module: undefined},
          {id: "DP", label: "Da pianificare", capacita: [], module: undefined},
          {id: "DA", label: "Da approvare", capacita: [], module: undefined},
          {id: "DAc", label: "Da accettare", capacita: [], module: undefined},
          {id: "IC", label: "In corso", capacita: [], module: undefined, },
          {id: "DC", label: "Da chiudere", capacita: [], module: undefined},
          {id: "B", label: "Bloccate", capacita: [], module: undefined},
          {id: "C", label:  "Chiuse", capacita: [], module: undefined},
          {id: "DF", label:  "Da Fatturare", capacita: [], module: undefined},
          {id: "F", label:  "Da Incassare", capacita: [], module: undefined},
          {id: "TT", label:  "Tutte", capacita: [], module: undefined},
        ]},

      // {id: "A", label: "Attivita", capacita: [], module: undefined},
      {id: "C", label: "CRM", capacita: [], module: undefined},
      {id: "W", label: "Visite", capacita: [], module: undefined},
      {id: "S", label: "Scadenze", capacita: [], module: "Scadenzario"},

      // {id: "F", label: "Fasi", capacita: [], module: undefined},
      // {id: "L", label: "Lezioni", capacita: [], module: undefined},
      // {id: "B", label: "Spese", capacita: [], module: undefined},
      // {id: "I", label: "Fatture", capacita: [], module: undefined},
      // {id: "V", label: "Valutazioni", capacita: [], module: undefined}
    ],
    RmaAddOn:{
      esitoLock:true,
      canOpenReadonly: true,
      fieldProgressivo: 'id',
      taskGenerico: false,
      AddinCalendar : false,
      vociDiCosto: false,
      Garanzia: false,
      provaAcquisto: true,
      CategoriaProdotto: true,
      TipoAcquisto: false,
      TipoAcquistoSource: "ClientiType",
      Rivenditore: false,
      NumeroDocumento: false,
      DataDocumento: false,
      Ricevuta: false,
      Targhetta: false,
      CircostanzaDifetto: false,
      DescrizioneDifetto: false,
      Difetto: true,
      Telefono: true,
      hidePriorita: true,
      EstensioneGaranzia: true,
      Allegati: true,
      Modello: true,
      Matricola: true,
      Brand: true,
      sottoTask: true,
      HidePAadd:true,
      HideRmaAdd: true,
      invioPec: true,
      Modelli: true,
      Pulsanti: [
        {nome: 'Approva pianific. Invia incarico', class:'btn btn-orange rounded-pill py-2', hideif: {fieldnotexist:'dispPersonale', fieldexist: 'data_assegnazione_tec'},  labelClass:'ml-1 text-white font-weight-bold', type: 'POST', restRoute: '/rma/rma/approvazione', capacita: ['gestione-commesse-daapprovare'], icon:'fas fa-check', successMex: "inviato", parameter: [{nome:"id", parameter: 'id'}]},
        {nome: 'Prendi in carico', noClose:true, class:'btn btn-orange rounded-pill py-2', hideif: {fieldnotexist:'dispPersonale', fieldexist: 'data_assegnazione_tecnico', fieldNotEqual: [{source: 'manager', value: null, externalParameter: 'currentUserId', internalParameter: 'currentUserId'}]},  labelClass:'ml-1 text-white font-weight-bold', type: 'POST', restRoute: '/rma/rma/presaincarico', capacita: [], icon:'fas fa-check', successMex: "Preso in carico",preAlert: "Dichiaro che prima di iniziare la collaborazione quale ispettore con Verifica SpA, in relazione all'impianto in questione, non ho svolto la attività di progettista, direttore dei lavori, collaudatore, installatore, manutentore o consulente; mi impegno altresì a non svolgere alcuna delle predette attività a favore del succitato committente per almeno 3 anni dopo lo svolgimento della verifica in oggetto e dichiaro infine che non esiste alcuna altra circostanza che possa limitare la mia imparzialità di giudizio, pertanto ACCETTO di svolgere i lavori per la commessa, nei tempi indicati nell'incarico.", parameter: [{nome:"id", parameter: 'id'}, {nome: 'presaInCarico', value: true}]},
        {nome: 'Rifiuta l\'incarico', class:'btn btn-orange rounded-pill py-2', hideif: {fieldnotexist:'dispPersonale', fieldexist: 'data_assegnazione_tecnico', fieldNotEqual: [{source: 'manager', value: null, externalParameter: 'currentUserId', internalParameter: 'currentUserId'}]},  labelClass:'ml-1 text-white font-weight-bold', type: 'POST', restRoute: '/rma/rma/presaincarico', capacita: [], icon:'fas fa-check', successMex: "Incarico Rifiutato", parameter: [{nome:"id", parameter: 'id'}, {nome: 'presaInCarico', value: false}]},
        { nome: 'Riporta allo stato iniziale', class: 'btn btn-orange rounded-pill py-2', hideif: { func: (item)=>{ return item?.manager && !item?.figli?.length} }, capacita: ['gestione-commesse-dapianificare'],  labelClass:'ml-1 text-white font-weight-bold', type: 'POST', restRoute: '/rma/rma/presaincarico', icon:'fas fa-check', successMex: "Commessa riportata allo stato iniziale", parameter: [{nome:"id", parameter: 'id'}, {nome: 'presaInCarico', value: false}]},
      ],
      CampiProvaAcquisto: [
        {
          modificabile: true,
          nome: 'RMA.product',
          oggetti: ['prodotto'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'RMA.matricola',
          oggetti: ['seriale'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Indirizzo',
          oggetti: ['acquirente'],
          field:['indirizzo'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          type:'object',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Cap',
          oggetti: ['acquirente'],
          field:['cap'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          type:'object',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Città',
          oggetti: ['acquirente'],
          field:['citta'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          type:'object',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Provincia',
          oggetti: ['acquirente'],
          field:['provincia'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          type:'object',
          visibleifProhibited: true
        },
      ],
      CampiRmaAdd: [
        {
          sezione:'Programmazione Attività',
          campi:[
            {nome:'Prima Verifica Entro:', oggetti: ['data_inizio_prevista'], modificabile:true , inputType:'date', class: 'col-lg-3' },
            {
              modificabile: true,
              nome: 'Periodicità Verifica:',
              oggetti: ['periodo'],
              inputType: 'singleDropdown',
              source: 'PeriodiSource',
              internalOutput: 'periodoObject',
              OutputField: 'periodo',
              placeholder: 'Seleziona',
              settings: {
                singleSelection: true,
                closeDropDownOnSelection: true,
                singleOutput: true,
                idField: 'id',
                textField: 'label',
                searchPlaceholderText: "inserire almeno 3 caratteri",
                allowSearchFilter: true,
                allowRemoteDataSearch: true,
              },
              class: 'col-lg-3',
              visibleifProhibited: true
            },
            {nome:'Straordinaria:', oggetti: ['straordinario'], type:'boolean', inputType:'checkbox', modificabile: true, class: 'col-lg-2' },
            {nome:'Commessa DPR/462:', oggetti: ['accreditato'], type:'boolean', inputType:'checkbox', modificabile: true, class: 'col-lg-2' },
            {nome:'Rinnovo Automatico:', oggetti: ['rinnovo'], type:'boolean', inputType:'checkbox', modificabile: true, class: 'col-lg-2' },
            {nome:'Note:', oggetti: ['note'], inputType:'textarea', modificabile: true, class: 'col-lg-12'},
            {nome:"Riferimento dell'offerta:", inputType:'textarea', oggetti: ['noteTecnico'], modificabile:true, class: 'col-lg-4' },
            {nome:'Preventivo €:', oggetti: ['prezzo'], modificabile: true, inputType: 'number', class: 'col-lg-4'},
            // {nome:'Invio accettazione:', oggetti: ['data_fine-prevista'],  inputType: 'date', modificabile:true, class: 'col-lg-4'},
            // {
            //   modificabile: true,
            //   nome: 'Ispettore',
            //   oggetti: ['manager'],
            //   inputType: 'singleDropdown',
            //   source: 'PersoneSource',
            //   internalOutput: 'managerObject',
            //   OnFilter: 'filtroManager',
            //   OnChange: 'OnManagerChange',
            //   OutputField: 'manager',
            //   placeholder: 'Seleziona',
            //   settings: {
            //     singleSelection: true,
            //     closeDropDownOnSelection: true,
            //     singleOutput: true,
            //     idField: 'id',
            //     textField: 'fullName',
            //     searchPlaceholderText: "inserire almeno 3 caratteri",
            //     allowSearchFilter: true,
            //     allowRemoteDataSearch: true,
            //   },
            //   class: 'col-lg-3',
            //   visibleifProhibited: true
            // },
            // {nome:'Via email:', oggetti: ['email'], type:'boolean', inputType:'checkbox', modificabile: true, , class: 'col-lg-1 d-flex align-items-center', titleClass: 'col-lg-1 d-flex align-items-center'},
            // {nome:'Via Fax:', oggetti: ['fax'], type:'boolean', inputType:'checkbox', modificabile: true, , class: 'col-lg-1 d-flex align-items-center', titleClass: 'col-lg-1 d-flex align-items-center'},
            // {nome:'Verbali in formato digitale:', oggetti: ['verbaliDigitali'], type:'boolean', inputType:'checkbox', modificabile: true,  class: 'col-lg-1 d-flex align-items-center'},
            {nome:'_log_', oggetti: ['attivita'], type:'checkboxArray', class: 'col-lg-12' },
          ]
        }
      ],
      SezioniRmaEdit: [
        {
          sezione: 'Scheda Cliente',
          campo: 'clienteObject',
          campi: [
            {nome:'Cliente:', oggetti: ['nominativo'], field: ['nominativo'], class: 'col-lg-11', inputClass:'ml-3'},
            {nome:'Tel:', oggetti: ['telefono'], field: ['telefono'], class: 'col-lg-3', inputClass:'ml-3'},
            {nome:'Email:', oggetti: ['email'], field: ['email'], class: 'col-lg-5', inputClass:'ml-3'},
            {nome:'Pec:', oggetti: ['pec'], field: ['pec'], class: 'col-lg-4', inputClass:'ml-3'},
            {nome:'Indirizzo:', oggetti: ['indirizzo'], field: ['indirizzo'], class: 'col-lg-11', inputClass:'ml-3'},
            {nome:'Referente:', oggetti: ['referenti'],  type:"object", field: ['fullName'], class: 'col-lg-5', inputClass:'ml-3'},
            {nome: '', oggetti: ['referenti'], type:"object", field: ['telefono'], class: 'col-lg-6', inputClass:'ml-3'},
            {nome:'Comune:', oggetti: ['citta'], class: 'col-lg-9', inputClass:'ml-3'},
            {nome:'Provincia:', oggetti: ['provincia'], class: 'col-lg-3', inputClass:'ml-3'},
          ]
        },
        {
          sezione: 'Commessa',
          campi:[
            { nome: 'Esercente:', oggetti: ['commessaObject'], field: ['consegna', 'nominativo'], type: 'object', class: 'col-lg-12', inputClass: 'ml-3' },
            {nome:'Referente Impresa:', oggetti: ['commessaObject'], field:['referente','nominativo'], type:'object', class: 'col-lg-4', inputClass:'ml-3'},
            {nome:'Tel Referente:', oggetti: ['commessaObject'], field:['referente','telefono'], type:'object', class: 'col-lg-4', inputClass:'ml-3'},
            {nome:'Attività Impresa:', oggetti: ['commessaObject'], field:['oggetto'], class: 'col-lg-4', inputClass:'ml-3'},
            {nome:'Protocollo:', oggetti: ['protocollo'], class: 'col-12', inputClass:'ml-3', allowedCapacity: ['gestione-commesse-daaprire']},
            {nome:'Data apertura:', oggetti: ['contrattoObject'], field: ['data_creazione'], inputType: 'date', class: 'col-lg-4', inputClass:'ml-3', allowedCapacity: ['gestione-commesse-daaprire']},
            {nome:'Importo Contabilizzato:', oggetti: ['contabilizzato'], class: 'col-lg-4', inputClass:'ml-3', inputType: 'euro', allowedCapacity: ['gestione-commesse-daaprire']},
            {nome:'Acconti Pagati:', oggetti: ['accontoPagato'], class: 'col-lg-4', inputClass:'ml-3', inputType: 'euro', allowedCapacity: ['gestione-commesse-daaprire']},
            {nome:'Ubicazione Impianto:', oggetti: ['acquirente','acquirente','acquirente','acquirente'], field: ['indirizzo','cap','citta','provincia'], class: 'col-lg-12', inputClass:'ml-3'},
            {nome: "Files", type: "Files", url:"/rma/rma/file", oggetti: ['contrattoObject'], modificabile:true, class: 'col-lg-12', OnChange: 'OnFilesChange', GestioneFile: false, allowedCapacity: ['gestione-commesse-daaprire'] }//era daapprovare ma ezio chiesto che i tecnici non devono vedere
          ]
        },
        {
          sezione:'Programmazione Attività',
          campi:[
            {nome:'Prima Verifica Entro:', oggetti: ['data_inizio_prevista'], modificabile:true , inputType:'date', class: 'col-lg-3' },
            {nome:'Data Appuntamento:', oggetti: ['data_fine_prevista'], modificabile:true , inputType:'date', class: 'col-lg-3' },
            // {nome:'Periodicità Verifica:', oggetti: ['periodo'], inputType:'singleDropdown', modificabile: true , class: 'col-lg-3', },
            {
              modificabile: true,
              nome: 'Periodicità Verifica:',
              oggetti: ['periodo'],
              inputType: 'singleDropdown',
              source: 'PeriodiSource',
              internalOutput: 'periodoObject',
              OutputField: 'periodo',
              placeholder: 'Seleziona',
              settings: {
                singleSelection: true,
                closeDropDownOnSelection: true,
                singleOutput: true,
                idField: 'id',
                textField: 'label',
                searchPlaceholderText: "inserire almeno 3 caratteri",
                allowSearchFilter: true,
                allowRemoteDataSearch: true,
              },
              class: 'col-lg-3',
              visibleifProhibited: true
            },
            {nome:'Straordinaria:', oggetti: ['straordinario'], type:'boolean', inputType:'checkbox', modificabile: true, class: 'col-lg-2' },
            {nome:'Commessa DPR/462:', oggetti: ['accreditato'], type:'boolean', inputType:'checkbox', modificabile: true, class: 'col-lg-2' },
            {nome:'Rinnovo Automatico:', oggetti: ['rinnovo'], type:'boolean', inputType:'checkbox', modificabile: true, class: 'col-lg-2' },
            {nome:'Note:', oggetti: ['note'], inputType:'textarea', modificabile: true, class: 'col-lg-12'},
            {nome:"Riferimento dell'offerta:", inputType:'textarea', type:'object', oggetti: ['commessaObject'], field:['codiceContratto'], modificabile:false, class: 'col-lg-4' },
            { nome: 'Preventivo €:', oggetti: ['prezzo'], modificabile: true, inputType: 'number', class: 'col-lg-4', visibleifProhibited: true, allowedCapacity: ['gestione-commesse-dapianificare'] },
            {nome:'Data assegnazione incarico:', oggetti: ['data_assegnazione_tec'],  inputType: 'date',  AllowedRoles:['AZ', 'SA'], modificabile: true, class: 'col-lg-4', visibleifProhibited: true },
            {nome:'Data accettazione ispettore:', oggetti: ['data_assegnazione_tecnico'],  inputType: 'date',  AllowedRoles:['AZ', 'SA'], modificabile: true, class: 'col-lg-4',visibleifProhibited: true },
           

            // {nome:'Via email:', oggetti: ['email'], type:'boolean', inputType:'checkbox', modificabile: true, , class: 'col-lg-1 d-flex align-items-center', titleClass: 'col-lg-1 d-flex align-items-center'},
            // {nome:'Via Fax:', oggetti: ['fax'], type:'boolean', inputType:'checkbox', modificabile: true, , class: 'col-lg-1 d-flex align-items-center', titleClass: 'col-lg-1 d-flex align-items-center'},
            // {nome:'Verbali in formato digitale:', oggetti: ['verbaliDigitali'], type:'boolean', inputType:'checkbox', modificabile: true,  class: 'col-lg-1 d-flex align-items-center'},

            {
              modificabile: true,
              nome: 'Ispettore',
              oggetti: ['manager'],
              inputType: 'singleDropdown',
              source: 'PersoneSource',
              internalOutput: 'managerObject',
              OnFilter: 'filtroManager',
              OnChange: 'OnManagerChange',
              OutputField: 'manager',
              placeholder: 'Seleziona',
              settings: {
                singleSelection: true,
                closeDropDownOnSelection: true,
                singleOutput: true,
                idField: 'id',
                textField: 'fullName',
                searchPlaceholderText: "inserire almeno 3 caratteri",
                allowSearchFilter: true,
                allowRemoteDataSearch: true,
              },
              class: 'col-lg-3',
              visibleifProhibited: true
            },
            {nome:"Note dell'Ispettore:", oggetti: ['noteIspettore'], inputType:'textarea', modificabile: true, class: 'col-lg-3'},
            {nome:'Attività', oggetti: ['attivita'], type:'checkboxArray', disabledif:{ hasNotCapacity: ['gestione-commesse-daaprire']}, class: 'col-lg-12', modificabile: true  },
            { nome: 'Tariffa INAIL:', oggetti: ['inail'], modificabile: true, inputType: 'number', class: 'col-lg-12 d-flex align-items-center', inputClass: 'ml-3', inputStyle: { width: '200px' }, visibleifProhibited: false, allowedCapacity: ['gestione-commesse-dapianificare'] },
            { nome: 'Ck disp. personale', oggetti: ['dispPersonale'], type: 'boolean', inputType: 'checkbox', modificabile: true, class: 'col-lg-3', visibleifProhibited: false, allowedCapacity: ['gestione-commesse-dapianificare'] },
            { nome: 'Ck disp. attrezzatura', oggetti: ['dispAttrezzature'], type: 'boolean', inputType: 'checkbox', modificabile: true, class: 'col-lg-3', visibleifProhibited: false, allowedCapacity: ['gestione-commesse-dapianificare'] },
            { nome: 'Ck tempi', oggetti: ['tempi'], type: 'boolean', inputType: 'checkbox', modificabile: true, class: 'col-lg-3', visibleifProhibited: false, allowedCapacity: ['gestione-commesse-dapianificare'] },
            { nome: 'Ck criticità', oggetti: ['criticita'], type: 'boolean', inputType: 'checkbox', modificabile: true, class: 'col-lg-3', visibleifProhibited: false, allowedCapacity: ['gestione-commesse-dapianificare'] },
          ]
        },
        {
          sezione:'Riesame rapporti di ispezione',
          campi:[
            {nome:'Chiusura Commessa:', oggetti: ['data_inizio_effettiva'],  inputType: 'date', modificabile:true, class: 'col-lg-4',visibleifProhibited: false, allowedCapacity: ['gestione-commesse-dachiudere'],},
            {nome:'Emissione Certificato:', oggetti: ['data_fine_effettiva'],  inputType: 'date', modificabile:true, class: 'col-lg-4',visibleifProhibited: false, allowedCapacity: ['gestione-commesse-dachiudere'],},
            {nome:'Da chiudere', oggetti: ['chiuso'], type:'boolean', disabledif:{fieldexist: 'chkDoc', fieldnotexist: 'data_assegnazione_tecnico'}, hideif:{fieldexist: 'chiuso'}, inputType:'checkbox', modificabile: true, class: 'col-lg-3',visibleifProhibited: false, allowedCapacity: ['gestione-commesse-incorso'], },
            {nome:'Da chiudere', oggetti: ['chiuso'], type:'boolean', disabledif:{fieldexist: 'chkDoc'}, hideif:{fieldnotexist: 'chiuso'}, inputType:'checkbox', modificabile: true, class: 'col-lg-3',visibleifProhibited: true, allowedCapacity: ['gestione-commesse-dachiudere'], },
            {nome:'Blocca', oggetti: ['bloccato'], type:'boolean', inputType:'checkbox',hideif:{fieldexist: 'bloccato'}, disabledif:{fieldexist: 'chkDoc'}, modificabile: true, class: 'col-lg-3',visibleifProhibited: false, allowedCapacity: ['gestione-commesse-dachiudere'], },
            {nome:'Sblocca', oggetti: ['bloccato'], type:'boolean', inputType:'checkbox', hideif:{fieldnotexist: 'bloccato'}, modificabile: true, class: 'col-lg-3',visibleifProhibited: false, allowedCapacity: ['gestione-commesse-dachiudere', 'gestione-commesse-bloccate'], },
            { nome: 'Data Blocco:', oggetti: ['dataBlocco'], inputType: 'date', hideif: { func: (x) => { return x.bloccato || x.dataBlocco }},  AllowedRoles: ['AZ', 'SA'], modificabile: true, class: 'col-lg-4', visibleifProhibited: true },
            { nome: 'Motivo', oggetti: ['motivoBlocco'], type: 'text', inputType: 'textarea', hideif: { func: (x) => { return x.bloccato || x.dataBlocco } },  modificabile: true, class: 'col-lg-9', visibleifProhibited: true, allowedCapacity: ['gestione-commesse-dachiudere'], },
            {nome:'Ck documentale', oggetti: ['chkDoc'], type:'boolean', inputType:'checkbox', modificabile: true, class: 'col-lg-3', prevDivClass:'col-12',visibleifProhibited: false, allowedCapacity: ['gestione-commesse-dachiudere'], },
            {nome:'Ck procedure di sicurezza', oggetti: ['chkProcedura'], type:'boolean', inputType:'checkbox', modificabile: true, class: 'col-lg-3',visibleifProhibited: false, allowedCapacity: ['gestione-commesse-dachiudere'] },
            {nome:'Ck misure e prove', oggetti: ['chkMisure'], type:'boolean', inputType:'checkbox', modificabile: true, class: 'col-lg-3',visibleifProhibited: false, allowedCapacity: ['gestione-commesse-dachiudere'] },
            {nome:'Ck coerenza', oggetti: ['chkCoerenza'], type:'boolean', inputType:'checkbox', modificabile: true, class: 'col-lg-3',visibleifProhibited: false, allowedCapacity: ['gestione-commesse-dachiudere'] },
            {nome: "Files", type: "Files", url:"/rma/rma/file", modificabile:true, class: 'col-lg-12', OnChange: 'OnFilesChange', hideif:{hasNotCapacity: ['tecnico']}, prohibitedRoles:['AZ','RT'], disabledif:{fieldexist: 'chkDoc', fieldnotexist: 'data_assegnazione_tecnico'}, campiAggiuntivi: [
                {
                  modificabile: true,
                  nome: 'Attività',
                  oggetti: ['attivita'],
                  inputType: 'singleDropdown',
                  source: 'attivita',
                  sourceFilter: {fieldexist:'risposta'},
                  internalOutput: 'attivitaObject',
                  OutputField: 'attivita',
                  placeholder: 'Seleziona',
                  settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'check',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                    selectIfSingle:true
                  },
                  class: 'col-lg-3',
                  visibleifProhibited: false
                },
              ]},
            {
              nome: "Files", type: "Files", url: "/rma/rma/file", modificabile: true, class: 'col-lg-12', OnChange: 'OnFilesChange', prohibitedRoles: ['AZ', 'RT'], hideif:{hasCapacity: ['tecnico', 'rt']},  disabledif:{fieldexist: 'chkDoc'}, campiAggiuntivi: [
                {
                  modificabile: true,
                  nome: 'Attività',
                  oggetti: ['attivita'],
                  inputType: 'singleDropdown',
                  source: 'attivita',
                  sourceFilter: {fieldexist:'risposta'},
                  internalOutput: 'attivitaObject',
                  OutputField: 'attivita',
                  placeholder: 'Seleziona',
                  settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'check',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                    selectIfSingle:true
                  },
                  class: 'col-lg-3',
                  visibleifProhibited: false
                },
              ]},
            {
              nome: "Files", type: "Files", url: "/rma/rma/file", modificabile: true, class: 'col-lg-12', OnChange: 'OnFilesChange', AllowedRoles: ['AZ', 'RT'], campiAggiuntivi: [
                {
                  modificabile: true,
                  nome: 'Attività',
                  oggetti: ['attivita'],
                  inputType: 'singleDropdown',
                  source: 'attivita',
                  sourceFilter: {fieldexist:'risposta'},
                  internalOutput: 'attivitaObject',
                  OutputField: 'attivita',
                  placeholder: 'Seleziona',
                  settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'check',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                    selectIfSingle:true
                  },
                  class: 'col-lg-3',
                  visibleifProhibited: false
                },
              ]
            }, {
              hideif: { hasNotCapacity: ['rt'] },
              nome: "Files", type: "Files", url: "/rma/rma/file", modificabile: true, class: 'col-lg-12', OnChange: 'OnFilesChange', campiAggiuntivi: [
                {
                  modificabile: true,
                  nome: 'Attività',
                  oggetti: ['attivita'],
                  inputType: 'singleDropdown',
                  source: 'attivita',
                  sourceFilter: { fieldexist: 'risposta' },
                  internalOutput: 'attivitaObject',
                  OutputField: 'attivita',
                  placeholder: 'Seleziona',
                  settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'check',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                    selectIfSingle: true
                  },
                  class: 'col-lg-3',
                  visibleifProhibited: false
                },
              ]
              }
          ]
        }
      ],
      CampiEsito: [
        {nome:'Data Inizio', oggetti: ['data_inizio_effettiva'],  inputType: 'datetime-local', modificabile:true, class: 'col-lg-3'},
        {nome:'Data Fine', oggetti: ['data_fine_effettiva'],  inputType: 'datetime-local', modificabile:true, class: 'col-lg-3'},
        {
          modificabile: true,
          nome: 'Esito',
          oggetti: ['stato'],
          inputType: 'singleDropdown',
          source: 'Tipi',
          internalOutput: 'statoObject',
          OutputField: 'stato',
          placeholder: 'Seleziona',
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'label',
            searchPlaceholderText: "Cerca",
            allowSearchFilter: true,
            allowRemoteDataSearch: false,
          },
          class: 'col-lg-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Attività',
          oggetti: ['attivita'],
          inputType: 'singleDropdown',
          source: 'checkSelezionate',
          internalOutput: 'attivitaObject',
          OutputField: 'attivita',
          placeholder: 'Seleziona',
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'check',
            searchPlaceholderText: "Cerca",
            allowSearchFilter: true,
            allowRemoteDataSearch: false,
          },
          class: 'col-lg-3',
          visibleifProhibited: true
        },
        {nome: "Files", type: "Files", url:"/rma/segnalazione/file", modificabile:true, class: 'col-lg-12', OnChange: 'OnFilesChange',}
      ]
    },
    ProgettiAddon: {
      commerciale: false,
      segnalatore: false,
      deleteSubProject: true,
      categoriaVoceDiCosto: true,
      lavorato: false,
      sottoOfferte: true,
      hideAdd: true,
      consegna: true,
      ReferenteOfferta: true,
      ReferenteSottoofferta: true,
      ProvaAcquisto: true, //impianto
      HideVoci: true,
      rma: true,
      sync: true,
      Revisioni: true,
      genericFiles: true,
      filters:[
        {nome:'Codice', required: false, oggetti: ['codice'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
      ],
      PulsantiLista: [
        {nome: 'Importa da Gamma', stato: ["CC","CA"], class:'btn btn-orange rounded-pill py-2',labelClass:'ml-1 text-white font-weight-bold', type: 'GET', restRoute: '/progetti/progetti/import', capacita: ['gestione-progetti'], icon:'fas fa-download', preMex: "Attenzione l'opreazione potrebbe richiedere alcuni minuti", successMex: "Commesse importate con successo"},
        {nome: 'Importa Commessa Singola', stato: ["CC","CA"], class:'btn btn-orange rounded-pill py-2',labelClass:'ml-1 text-white font-weight-bold', campi:[ {nome:'Commessa', required: true, oggetti: ['commessa'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-12'}], type: 'POST', restRoute: '/progetti/progetti/import/commessa', capacita: ['gestione-progetti'], icon:'fas fa-download', preMex: "Attenzione l'opreazione potrebbe richiedere alcuni minuti", successMex: "Commessa importata con successo"}
      ],
      CampiNuovoCliente: [
        {nome:'cliente.nominativo', prevDivClass:"col-12", required: true, oggetti: ['nominativo'], modificabile: true, inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
        {nome:'cliente.piva', required: false, oggetti: ['piva'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
        {nome:'cliente.pec', required: false, oggetti: ['pec'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
        {nome:'cliente.email', requiredif: {fieldexist: 'username'}, oggetti: ['email'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
        {nome:'cliente.indirizzo', required: false, oggetti: ['indirizzo'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
        {nome:'cliente.cap', required: false, oggetti: ['cap'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
        {nome:'cliente.citta', required: false, oggetti: ['citta'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
        {nome:'cliente.provincia', required: false, oggetti: ['provincia'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
        {nome:'cliente.telefono', required: false, oggetti: ['telefono'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
        {nome:'cliente.referente.nome', required: false, oggetti: ['referenteObject'], field:['nome'], type:'object', modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
        {nome:'cliente.referente.cognome', nextDivClass:"col-12", required: false, oggetti: ['referenteObject'], type:'object', field:['cognome'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
        {nome:'cliente.referente.telefono', nextDivClass:"col-12", required: false, oggetti: ['referenteObject'], type:'object', field:['telefono'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
        {nome:'cliente.referente.email', nextDivClass:"col-12", required: false, oggetti: ['referenteObject'], type:'object', field:['email'], modificabile: true , inputType:'text',titleClass: 'control-label col-form-label', class: 'col-lg-3'},
      ],
      CampiOfferta: [
        {
          modificabile: true,
          nome: 'Offerta.Responsabile',
          oggetti: ['manager'],
          inputType: 'singleDropdown',
          source: 'managers',
          internalOutput: 'managerObject',
          OnFilter: 'filtroManager',
          OnChange: 'OnManagerChange',
          placeholder: 'Seleziona',
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'fullName',
            searchPlaceholderText: "inserire almeno 3 caratteri",
            allowSearchFilter: true,
            allowRemoteDataSearch: true,
          },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Compilatore',
          oggetti: ['commerciale'],
          inputType: 'singleDropdown',
          source: 'managers',
          internalOutput: 'commerciale',
          OnFilter: 'filtroCommerciale',
          placeholder: 'Seleziona',
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'fullName',
            searchPlaceholderText: "inserire almeno 3 caratteri",
            allowSearchFilter: true,
            allowRemoteDataSearch: true,
          },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Oggetto:',
          oggetti: ['oggetto'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Preventivo N:',
          oggetti: ['codice'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-2',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Commessa N:',
          oggetti: ['codiceContratto'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-2',
          visibleifProhibited: true
        },
        {
          nome: 'Contratto Caricato',
          modificabile: true,
          oggetti: ['caricatoContratto'],
          inputType: 'checkbox',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-2',
          allowedCapacity: ['gestione-progetti'],
          visibleifProhibited: true
        },
        {
          nome: 'Contratto Annullato',
          modificabile: true,
          oggetti: ['annullato'],
          inputType: 'checkbox',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-2',
          allowedCapacity: ['gestione-progetti'],
          visibleifProhibited: true
        },
        {
          prevDivClass: "col-md-12",
          modificabile: true,
          nome: 'Offerta.Creazione',
          oggetti: ['data_creazione'],
          disabledif:{func: (x, permission)=>{
            return !x.data_creazione || permission.isOperativeRoles(['AZ','SA']);
          }},
          inputType: 'date',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Offerta.Offerta',
          oggetti: ['data_emissione'],
          disabledif:{func: (x, permission)=>{
              return !x.data_emissione || permission.isOperativeRoles(['AZ','SA']);
            }},
          inputType: 'date',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Offerta.Accettazione',
          oggetti: ['data_accettazione'],
          disabledif:{func: (x, permission)=>{
              return !x.data_accettazione || permission.isOperativeRoles(['AZ','SA']);
            }},
          inputType: 'date',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Offerta.Conferma',
          oggetti: ['data_conferma'],
          disabledif:{func: (x, permission)=>{
              return !x.data_conferma || permission.isOperativeRoles(['AZ','SA']);
            }},
          inputType: 'date',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Note',
          oggetti: ['note'],
          inputType: 'textarea',
          titleClass: 'control-label col-form-label',
          class: 'col-md-6',
          visibleifProhibited: true
        },
      ],
      CampiSottoOfferta: [
        {
          prevDivClass: 'col-12',
          modificabile: false,
          nome: 'Esercente',
          oggetti: ['Esercente'],
          inputType: 'text',
          titleClass: ' card-title',
          titleStyle:{ fontSize:'16px'},
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          prevDivClass: 'col-12',
          modificabile: true,
          nome: 'Nominativo Esercente',
          oggetti: ['consegna'],
          field: ['nominativo'],
          inputType: 'text',
          type:'object',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Indirizzo Esercente',
          oggetti: ['consegna'],
          field: ['indirizzo'],
          inputType: 'text',
          type:'object',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'CAP Esercente',
          oggetti: ['consegna'],
          field: ['cap'],
          inputType: 'text',
          type:'object',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Città Esercente',
          oggetti: ['consegna'],
          field: ['citta'],
          inputType: 'text',
          type:'object',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Provincia Esercente',
          oggetti: ['consegna'],
          field: ['provincia'],
          inputType: 'text',
          type:'object',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          prevDivClass: 'col-12',
          modificabile: true,
          nome: 'Nominativo Referente',
          oggetti: ['referente'],
          field: ['nominativo'],
          type:'object',
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Telefono Referente',
          oggetti: ['referente'],
          field: ['telefono'],
          type:'object',
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          prevDivClass: 'col-12',
          modificabile: true,
          nome: 'Email Referente',
          oggetti: ['referente'],
          field: ['email'],
          type:'object',
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Mansione Referente',
          oggetti: ['referente'],
          field: ['mansione'],
          type:'object',
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Attività impresa',
          oggetti: ['oggetto'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },

      ],
      CampiSottoOffertaPreventivo: [// se pieno viene gestito preventivo
        {
          sezione:'Esercente',
          campi:[
            {
              prevDivClass: 'col-12',
              modificabile: true,
              nome: 'Nominativo',
              oggetti: ['consegna'],
              field: ['nominativo'],
              inputType: 'text',
              type:'object',
              titleClass: 'control-label col-form-label',
              class: 'col-md-3',
              visibleifProhibited: true
            },
            {
              modificabile: true,
              nome: 'Indirizzo',
              oggetti: ['consegna'],
              field: ['indirizzo'],
              inputType: 'text',
              type:'object',
              titleClass: 'control-label col-form-label',
              class: 'col-md-3',
              visibleifProhibited: true
            },
            {
              modificabile: true,
              nome: 'CAP',
              oggetti: ['consegna'],
              field: ['cap'],
              inputType: 'text',
              type:'object',
              titleClass: 'control-label col-form-label',
              class: 'col-md-3',
              visibleifProhibited: true
            },
            {
              modificabile: true,
              nome: 'Città',
              oggetti: ['consegna'],
              field: ['citta'],
              inputType: 'text',
              type:'object',
              titleClass: 'control-label col-form-label',
              class: 'col-md-3',
              visibleifProhibited: true
            },
            {
              modificabile: true,
              nome: 'Provincia',
              oggetti: ['consegna'],
              field: ['provincia'],
              inputType: 'text',
              type:'object',
              titleClass: 'control-label col-form-label',
              class: 'col-md-3',
              visibleifProhibited: true
            },
          ]
        },
        {
          sezione: 'Impianto',
          campi: [

            {
              modificabile: true,
              nome: 'Nominativo Referente',
              oggetti: ['referente'],
              field: ['nominativo'],
              type:'object',
              inputType: 'text',
              titleClass: 'control-label col-form-label',
              class: 'col-md-3',
              visibleifProhibited: true
            },
            {
              modificabile: true,
              nome: 'Telefono Referente',
              oggetti: ['referente'],
              field: ['telefono'],
              type:'object',
              inputType: 'text',
              titleClass: 'control-label col-form-label',
              class: 'col-md-3',
              visibleifProhibited: true
            },
            {
              prevDivClass: 'col-12',
              modificabile: true,
              nome: 'Email Referente',
              oggetti: ['referente'],
              field: ['email'],
              type:'object',
              inputType: 'text',
              titleClass: 'control-label col-form-label',
              class: 'col-md-3',
              visibleifProhibited: true
            },
            {
              modificabile: true,
              nome: 'Mansione Referente',
              oggetti: ['referente'],
              field: ['mansione'],
              type:'object',
              inputType: 'text',
              titleClass: 'control-label col-form-label',
              class: 'col-md-3',
              visibleifProhibited: true
            },
            {
              modificabile: true,
              nome: 'Attività impresa',
              oggetti: ['oggetto'],
              inputType: 'text',
              titleClass: 'control-label col-form-label',
              class: 'col-md-3',
              visibleifProhibited: true
            },
            { prevDivClass: "col-12", modificabile: true, nome: 'Indirizzo', oggetti: ['provaAcquisto'], field:['indirizzo'], inputType: 'text', titleClass: 'control-label col-form-label', class: 'col-lg-3', type:'object', visibleifProhibited: true},
            {modificabile: true, nome: 'Cap', oggetti: ['provaAcquisto'], field:['cap'], inputType: 'text', titleClass: 'control-label col-form-label', class: 'col-lg-3', type:'object', visibleifProhibited: true},
            {modificabile: true, nome: 'Città', oggetti: ['provaAcquisto'], field:['citta'], inputType: 'text', titleClass: 'control-label col-form-label', class: 'col-lg-3', type:'object', visibleifProhibited: true},
            {modificabile: true, nome: 'Provincia', oggetti: ['provaAcquisto'], field:['provincia'], inputType: 'text', titleClass: 'control-label col-form-label', class: 'col-lg-3', type:'object', visibleifProhibited: true},
            {nome:'ISP', oggetti: ['attivita'], type:'checkboxArray', class: 'col-lg-12', modificabile: true },
            {nome:'LAB', oggetti: ['laboratorio'], type:'checkboxArray', class: 'col-lg-12', modificabile: true },
            {modificabile: true, nome: 'Prezzo', oggetti: ['prezzo'], inputType: 'number', titleClass: 'control-label col-form-label', class: 'col-lg-3', visibleifProhibited: true},
          ]
        }
      ],
      CampiVociPreventivo: [
        {nome:'Impianto', oggetti:['provaAcquisto','provaAcquisto','provaAcquisto','provaAcquisto'], field: ['indirizzo', 'cap', 'citta', 'provincia'], suffix:' ', type: 'object'},
        {nome:'Esercente', oggetti:['consegna','consegna','consegna','consegna','consegna'], field: ['nominativo','indirizzo', 'cap', 'citta', 'provincia'], suffix:' ', type: 'object'},
        {nome:'Prezzo €', oggetti:['prezzo'], inputType: 'euro'},
      ]
    },
    Documenti: {
      campiDocumento: [
        {
          modificabile: true,
          nome: 'Descrizione',
          oggetti: ['descrizione'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-12',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Old',
          oggetti: ['old'],
          inputType: 'checkbox',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-12',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'File',
          oggetti: [null],
          inputType: 'file',
          placeholder: '(pdf,doc,docx,xls,xlsx,jpg,png,txt,ods,odt) Size Limit: 5.00 MB',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Categorie',
          oggetti: ['categorie'],
          inputType: 'singleDropdown',
          source: 'CategorieLineare',
          OnFilter: 'filtroCategoria',
          internalOutput: 'Categorie',
          hideif:{fieldexist:'id'},
          placeholder: 'Seleziona',
          settings: {
            singleSelection: false,
            idField: 'id',
            textField: 'categoria',
            searchPlaceholderText: "inserire almeno 3 caratteri",
            allowSearchFilter: true,
            allowRemoteDataSearch: true,
          },
          class: 'col-lg-12',
          visibleifProhibited: true
        },
        {nome:'_log_', oggetti: ['checks'], type:'checkboxArray', class: 'col-lg-12', source:'/rma/rma/attivita', modificabile: true, HideCampiAgg: true },

      ]
    },
    PersoneMultiSelect: {
      singleSelection: false,
      closeDropDownOnSelection: true,
      idField: 'id',
      textField: 'fullName',
      searchPlaceholderText: "inserire almeno 3 caratteri",
      allowSearchFilter: true,
      allowRemoteDataSearch: true,
    },
    //#region Menu
    Menu: [
      {
        title: "Menu",
        class: "has-arrow",
        label: "Menu",
        extralink: true
      },
      {
        module: "Personale",
        path: "/listautenti/personale",
        title: 'menu.PersonaleLabel',
        icon: "far fa-user",
        capacita: ["menu-persone"]
      },

      {
        module: "Clienti",
        path: "/listautenti/aziende",
        title: 'menu.clienti',
        icon: "mdi mdi-account-multiple",
        capacita: ["menu-clienti"]
      },
      {
              module: "Progetti",
              path: "progetti/progetti",
              title: 'menu.progetti',
              class: "has-arrow",
              icon: "mdi mdi-calendar-multiple-check",
              submenu: [
                {
                  path: "progetti/progetto",
                  title: 'Nuova offerta',
                  icon: "mdi mdi-calendar-multiple-check",
                  capacita: ["menu-nuova-offerta"]
                },
                {
                  module: "Progetti",
                  path: "progetti/progetti/CR",
                  title: 'Da inviare',
                  icon: "mdi mdi-calendar",
                  capacita: ["menu-offerte-dainviare"]
                },
                {
                  module: "Progetti",
                  path: "progetti/progetti/EM",
                  title: 'Da accettare',
                  icon: "mdi mdi-calendar",
                  capacita: ["menu-offerte-inviate"]
                },
                {
                  module: "Progetti",
                  path: "progetti/progetti/AC",
                  title: 'Accettate',
                  icon: "mdi mdi-calendar",
                  capacita: ["menu-offerte-accettate"]
                },
                {
                  module: "Progetti",
                  path: "progetti/progetti",
                  title: 'Tutte',
                  icon: "mdi mdi-calendar",
                  capacita: ["menu-progetti"]
                },
              ],
              capacita: ["menu-progetti", "menu-offerte-dainviare", "menu-offerte-inviate", "menu-offerte-accettate"]
            },
            {
              module: "Progetti",
              path: "progetti/progetti",
              title: 'Contratti',
              class: "has-arrow",
              icon: "mdi mdi-calendar-multiple-check",
              submenu: [
                {
                  module: "Progetti",
                  path: "progetti/progetti/CA",
                  title: 'Aperti',
                  icon: "mdi mdi-calendar",
                  capacita: ["menu-contratti"]
                },
                {
                  module: "Progetti",
                  path: "progetti/progetti/CC",
                  title: 'Tutti',
                  icon: "mdi mdi-calendar",
                  capacita: ["menu-contratti"]
                },
              ],
              capacita: ["menu-contratti"]
            },
      {
        module: "Task",
        path: "task/unita",
        title: 'Calendario',
        icon: "mdi mdi-calendar",
      },
      {
        module: "Rma",
        title: 'Commesse Isp',
        icon: "mdi mdi-calendar-multiple-check",//mdi-calendar-multiple-check //mdi-engine-outline
        class: "has-arrow",
        submenu: [
          {
            module: "Rma",
            path: "task/list/RA/DAP",
            title: 'Da Aprire',
            icon: "mdi mdi-calendar",
            capacita: ["menu-commesse-daaprire"]
          },
          {
            module: "Rma",
            path: "task/list/RA/DP",
            title: 'Da pianificare',
            icon: "mdi mdi-calendar",
            capacita: ["menu-commesse-dapianificare"]
          },
          {
            module: "Rma",
            path: "task/list/RA/DA",
            title: 'Da approvare',
            icon: "mdi mdi-calendar",
            capacita: ["menu-commesse-daapprovare"]
          },
          {
            module: "Rma",
            path: "task/list/RA/DAc",
            title: "Da accettare",
            icon: "mdi mdi-calendar",
            capacita: ["menu-commesse-daaccettare"]
          },
          {
            module: "Rma",
            path: "task/list/RA/IC",
            title: 'In corso',
            icon: "mdi mdi-calendar",
            capacita: ["menu-commesse-incorso"]
          },
          {
            module: "Rma",
            path: "task/list/RA/DC",
            title: 'Da chiudere',
            icon: "mdi mdi-calendar",
            capacita: ["menu-commesse-dachiudere"]
          },
          {
            module: "Rma",
            path: "task/list/RA/B",
            title: 'Bloccate',
            icon: "mdi mdi-calendar",
            capacita: ["menu-commesse-bloccate"]
          },
          {
            module: "Rma",
            path: "task/list/RA/C",
            title: 'Chiuse',
            icon: "mdi mdi-calendar",
            capacita: ["menu-commesse-chiuse"]
          },
          {
            module: "Rma",
            path: "task/list/RA/DF",
            title: 'Da Fatturare',
            icon: "mdi mdi-calendar",
            capacita: ["menu-fatture"]
          },
          {
            module: "Rma",
            path: "task/list/RA/F",
            title: 'Da Incassare',
            icon: "mdi mdi-calendar",
            capacita: ["menu-fatture"]
          },
          {
            module: "Rma",
            path: "task/list/RA/TT",
            title: 'Tutte',
            icon: "mdi mdi-calendar",
            capacita: ["menu-commesse-tutte"]
          },
        ],
        capacita: [
          "menu-commesse-tutte",
          "menu-commesse-daaprire",
          "menu-commesse-dapianificare",
          "menu-commesse-daapprovare",
          "menu-commesse-daaccettare",
          "menu-commesse-incorso",
          "menu-commesse-dachiudere",
          "menu-commesse-bloccate",
          "menu-commesse-chiuse",
          "menu-fatture"
        ]
      },
      // {
      //   module: "Rma",
      //   title: 'Commesse Lab',
      //   icon: "mdi mdi-calendar-multiple-check",//mdi-calendar-multiple-check //mdi-engine-outline
      //   class: "has-arrow",
      //   submenu: [
      //     {
      //       module: "Rma",
      //       path: "task/list/Z/DAP",
      //       title: 'Da Aprire',
      //       icon: "mdi mdi-calendar",
      //       capacita: ["menu-commesse-daaprire"]
      //     },
      //     {
      //       module: "Rma",
      //       path: "task/list/Z/DP",
      //       title: 'Da pianificare',
      //       icon: "mdi mdi-calendar",
      //       capacita: ["menu-commesse-dapianificare"]
      //     },
      //     {
      //       module: "Rma",
      //       path: "task/list/Z/DA",
      //       title: 'Da approvare',
      //       icon: "mdi mdi-calendar",
      //       capacita: ["menu-commesse-daapprovare"]
      //     },
      //     {
      //       module: "Rma",
      //       path: "task/list/Z/DAc",
      //       title: "Da accettare",
      //       icon: "mdi mdi-calendar",
      //       capacita: ["menu-commesse-daaccettare"]
      //     },
      //     {
      //       module: "Rma",
      //       path: "task/list/Z/IC",
      //       title: 'In corso',
      //       icon: "mdi mdi-calendar",
      //       capacita: ["menu-commesse-incorso"]
      //     },
      //     {
      //       module: "Rma",
      //       path: "task/list/Z/DC",
      //       title: 'Da chiudere',
      //       icon: "mdi mdi-calendar",
      //       capacita: ["menu-commesse-dachiudere"]
      //     },
      //     {
      //       module: "Rma",
      //       path: "task/list/Z/B",
      //       title: 'Bloccate',
      //       icon: "mdi mdi-calendar",
      //       capacita: ["menu-commesse-bloccate"]
      //     },
      //     {
      //       module: "Rma",
      //       path: "task/list/Z/C",
      //       title: 'Chiuse',
      //       icon: "mdi mdi-calendar",
      //       capacita: ["menu-commesse-chiuse"]
      //     },
      //     {
      //       module: "Rma",
      //       path: "task/list/Z/DF",
      //       title: 'Da Fatturare',
      //       icon: "mdi mdi-calendar",
      //       capacita: ["menu-fatture"]
      //     },
      //     {
      //       module: "Rma",
      //       path: "task/list/Z/F",
      //       title: 'Da Incassare',
      //       icon: "mdi mdi-calendar",
      //       capacita: ["menu-fatture"]
      //     },
      //     {
      //       module: "Rma",
      //       path: "task/list/Z/TT",
      //       title: 'Tutte',
      //       icon: "mdi mdi-calendar",
      //       capacita: ["menu-commesse-tutte"]
      //     },
      //   ],
      //   capacita: [
      //     "menu-commesse-tutte",
      //     "menu-commesse-daaprire",
      //     "menu-commesse-dapianificare",
      //     "menu-commesse-daapprovare",
      //     "menu-commesse-daaccettare",
      //     "menu-commesse-incorso",
      //     "menu-commesse-dachiudere",
      //     "menu-commesse-bloccate",
      //     "menu-commesse-chiuse",
      //     "menu-fatture"
      //   ]
      // },

      {
        module: "Documenti",
        path: "progetti/documenti",
        title: 'menu.DocumentiLabel',
        icon: "mdi mdi-file-document",
        capacita: ["menu-documenti"]
      },
      {
        module: "Stampe",
        path: "stampe/templates",
        title: "menu.StampeLabel",
        icon: "mdi mdi-printer",
        class: "has-arrow",
        capacita: ["menu-stampa", "menu-blocchi"],
        submenu: [
          {
            path: "stampe/templates",
            title: "Templates",
            capacita: ["gestione-stampa"]
          },
          {
            path: "stampe/marker",
            title: "Marker",
            capacita: ["menu-stampa"]
          },
          {
            path: "/blocchi/blocchi/list",
            title: "Blocchi",
            capacita: ["menu-blocchi"]
          }
        ]
      },
      {
        module: "Settings",
        path: "settings/settings",
        title: "menu.SettingsLabel",
        icon: "mdi-settings",
        capacita: ["menu-settings"]
      },
      {
        module: "Settings",
        path: "settings/smtp",
        title: "menu.smtp",
        icon: "mdi-settings",
        capacita: ["menu-settings"]
      },
      {
        module: "Admin",
        path: "listautenti/roles",
        title: "Ruoli",
        icon: "mdi mdi-lock-open-outline",
        capacita: ["menu-ruoli"]
      },

    ],

  }


  return env;
};
